<template>
  <template v-if="fullFooter">
    <footer class="footer mt-auto">
      <div class="mt-14 flex justify-between px-4 max-lg:flex-col lg:px-12">
        <figure class="max-lg:mb-4">
          <img loading="lazy" :src="props.footer.logo.url" :alt="props.footer.logo.alt" />
        </figure>
        <nav class="max-lg:mt-4">
          <h4 class="mb-3 text-lg text-neutral-low-light">
            {{ props.footer.menu.home.title }}
          </h4>
          <ul class="text-md">
            <li v-for="item in props.footer.menu.home.items" :key="item" class="mb-2">
              <a
                :href="item.url"
                :target="item.target"
                :title="item.label"
                class="text-neutral-low-ultra transition-colors hover:text-primary-default"
                >{{ item.label }}</a
              >
            </li>
          </ul>
        </nav>
        <nav class="max-lg:mt-4">
          <h4 class="mb-3 text-lg text-neutral-low-light">
            {{ props.footer.menu.help.title }}
          </h4>
          <ul class="text-md">
            <li v-for="item in props.footer.menu.help.items" :key="item" class="mb-2">
              <a
                :href="item.url"
                :target="item.target"
                :title="item.label"
                class="text-neutral-low-ultra transition-colors hover:text-primary-default"
                >{{ item.label }}</a
              >
            </li>
          </ul>
        </nav>
        <nav class="max-lg:mt-4">
          <a
            :href="props.footer.menu.wantHelp.url"
            :target="props.footer.menu.wantHelp.target"
            class="-mt-7 block text-lg font-bold text-neutral-low-light"
          >
            {{ props.footer.menu.wantHelp.title }}
            <img
              loading="lazy"
              class="inline"
              :src="props.footer.menu.wantHelp.image.url"
              :class="props.footer.menu.wantHelp.css"
              :alt="props.footer.menu.wantHelp.image.alt"
            />
          </a>
          <div class="-mt-2">
            <ul>
              <li v-for="item in props.footer.menu.wantHelp.items" :key="item">
                <p class="mb-2 text-md text-neutral-low-ultra transition-colors">
                  {{ item.label }}
                  <component :is="item.link.href === '' ? 'span' : 'a'" :href="item.link.href">
                    {{ item.link.label }}
                  </component>
                </p>
              </li>
            </ul>
          </div>
        </nav>
        <nav>
          <h4 class="mb-3 text-md text-neutral-low-light">
            {{ props.footer.menu.social.title }}
          </h4>
          <ul class="text-md">
            <li v-for="item in props.footer.menu.social.items" :key="item" class="mr-3 inline">
              <a :href="item.url" :target="item.target" :title="item.label" class="text-neutral-low-ultra"
                ><i class="text-black transition-colors hover:text-primary-default" :class="item.icon"
              /></a>
            </li>
          </ul>
        </nav>
      </div>
      <div class="mb-14 mt-12 px-6 lg:grid lg:grid-cols-12 lg:px-14">
        <div class="lg:col-span-6 lg:col-start-4">
          <h5 class="mb-3 text-md text-neutral-low-light">
            {{ props.footer.payments.title }}
          </h5>
          <ul class="max-lg:flex">
            <li v-for="item in props.footer.payments.items" :key="item" class="mr-4 inline">
              <img loading="lazy" class="inline" :src="item.image.url" :alt="item.image.alt" />
            </li>
          </ul>
        </div>
      </div>
      <div
        class="mt-14 flex justify-between border-t border-solid px-6 py-8 max-lg:flex-col max-lg:pb-16 lg:mb-16 lg:px-14"
      >
        <div class="text-neutral-low- text-md">
          <small class="text-md">
            {{ props.footer.copyright }}
          </small>
          <address class="not-italic">
            {{ props.footer.address }}
          </address>
        </div>
        <ul class="flex max-lg:mt-6 max-lg:flex-col">
          <li v-for="item in props.footer.menu.legal.items" :key="item" class="mr-8 inline last:mr-0">
            <a
              class="inline text-md text-neutral-low-light transition-colors hover:text-primary-default"
              :href="item.url"
              :title="item.label"
              :target="item.target"
            >
              <span v-if="item.click" @click.prevent="handleClick(item.click)">
                {{ item.label }}
              </span>
              <template v-else>
                {{ item.label }}
              </template>
            </a>
          </li>
        </ul>
      </div>
    </footer>
  </template>
  <template v-else>
    <footer class="footer internal mt-auto flex flex-col">
      <div class="mt-14 flex justify-between px-4 max-lg:flex-col lg:px-12">
        <figure class="max-lg:mb-4">
          <img loading="lazy" :src="props.footer.logo.url" :alt="props.footer.logo.alt" />
        </figure>
        <nav class="max-lg:mt-4 lg:ml-auto lg:mr-20">
          <a
            :href="props.footer.menu.wantHelp.url"
            :target="props.footer.menu.wantHelp.target"
            class="-mt-7 mb-3 block text-lg font-bold text-neutral-low-light"
          >
            {{ props.footer.menu.wantHelp.title }}
            <img
              loading="lazy"
              class="inline"
              :src="props.footer.menu.wantHelp.image.url"
              :class="props.footer.menu.wantHelp.css"
              :alt="props.footer.menu.wantHelp.image.alt"
            />
          </a>
          <div class="-mt-2">
            <ul>
              <li v-for="item in props.footer.menu.wantHelp.items.slice(1)" :key="item">
                <p class="mb-2 text-md text-neutral-low-ultra transition-colors">
                  {{ item.label }}
                  <component :is="item.link.href === '' ? 'span' : 'a'" :href="item.link.href">
                    {{ item.link.label }}
                  </component>
                </p>
              </li>
            </ul>
          </div>
        </nav>
        <nav>
          <!-- <h4 class="mb-3 text-md text-neutral-low-light">
            {{ props.footer.menu.social.title }}
          </h4> -->
          <ul class="text-md">
            <li v-for="item in props.footer.menu.social.items" :key="item" class="mr-3 inline">
              <a :href="item.url" :target="item.target" :title="item.label" class="text-neutral-low-ultra"
                ><i class="text-black transition-colors hover:text-primary-default" :class="item.icon"
              /></a>
            </li>
          </ul>
        </nav>
      </div>
      <div class="mt-auto flex justify-between border-t border-solid px-4 py-8 max-lg:flex-col max-lg:pb-16 lg:px-14">
        <div class="text-neutral-low- text-md">
          <small class="text-md"> {{ props.footer.copyright }} | {{ props.footer.address }} </small>
        </div>
        <ul class="flex max-lg:mt-6 max-lg:flex-col">
          <li v-for="item in props.footer.menu.legal.items" :key="item" class="mr-8 inline last:mr-0">
            <a class="text-neutral-low- inline text-md" :href="item.url" :title="item.label" :target="item.target">
              {{ item.label }}
            </a>
          </li>
        </ul>
      </div>
    </footer>
  </template>
</template>
<script setup>
const props = defineProps({
  footer: Object,
  fullFooter: Boolean,
});

const handleClick = (clickEvent) => {
  if (clickEvent) {
    const [functionName, argument] = parseClickEvent(clickEvent);
    if (functionName) {
      if (typeof window[functionName] === "function") {
        window[functionName](argument);
      } else {
        console.error(`A função ${functionName} não foi encontrada.`);
      }
    }
  }
};

const parseClickEvent = (clickEvent) => {
  const [functionName, argument] = clickEvent.slice(0, -1).split("(");
  return [functionName.trim(), argument.slice(1, -1)];
};
</script>

<style lang="scss" scoped>
@import "./../styles/themes/tailwind/tailwind-light/variables";
.footer {
  height: auto;
  background-color: $neutralColorHighUltra;
  @media screen and (min-width: 1024px) {
    height: 417px;
    &.internal {
      height: 192px;
    }
  }
}
</style>
